import store from '@store';
import Counsels from '@views/Counsels';
import CreateStaffAccount from '@views/CreateStaffAccount';
import FindId from '@views/FindId';
import Login from '@views/Login';
import MemberDetail from '@views/MemberDetail';
import MemberForm from '@views/MemberForm';
import Members from '@views/Members';
import NotFound from '@views/NotFound';
import ProductDetail from '@views/ProductDetail';
import Products from '@views/Products';
import ProductsFormContainer from '@views/ProductsFormContainer';
import ResetPassword from '@views/ResetPassword';
import ResetPasswordLink from '@views/ResetPasswordLink';
import Schedule from '@views/Schedule';
import StaffDetail from '@views/StaffDetail';
import StaffForm from '@views/StaffForm';
import Staffs from '@views/Staffs';
import UnifiedAccount from '@views/UnifiedAccount';
// import TicketCreateForm from '@views/TicketCreateForm';
import SettingsMain from '@components/Settings';
import SettingsAutoMessageNotification from '@components/Settings/AutoMessageNotification/index';
import SettingsDivisions from '@components/Settings/Divisions';
import SettingsOperations from '@components/Settings/Operations';
import SettingsPayments from '@components/Settings/Payments';
import SettingsPermissions from '@components/Settings/Permissions';
import SettingsRequired from '@components/Settings/Required';
import SettingsRooms from '@components/Settings/Rooms';
import SettingsServicePayments from '@components/Settings/ServicePayments';
import SettingsUserGrades from '@components/Settings/UserGrades';
import Boards from '@views/Boards';
import Bookings from '@views/Bookings';
import BulkBookings from '@views/BulkBookings';
import Checkin from '@views/Checkin';
import CheckinLectures from '@views/CheckinLectures';
import CreatePrivateLesson from '@views/CreatePrivateLesson';
import DeletedLectures from '@views/DeletedLectures';
import GroupLessonForm from '@views/GroupLessonForm';
import LectureDetail from '@views/LectureDetail';
import LectureEdit from '@views/LectureEdit';
import Lectures from '@views/Lectures';
import Locker from '@views/Locker';
import Messages from '@views/Messages';
import Sales from '@views/Sales';
import SalesContainer from '@views/SalesContainer';
import SalesNew from '@views/SalesNew';
import SalesPoint from '@views/SalesPoint';
import SalesReport from '@views/SalesReport';
import SalesSpending from '@views/SalesSpending';
import SalesUnpaid from '@views/SalesUnpaid';
import ServiceInspection from '@views/ServiceInspection';
import Settings from '@views/Settings';
import TicketHistory from '@views/TicketHistory';

export default [
  /** Inspection 서비스 점검 */
  {
    path: '/service-inspection',
    name: 'service-inspection',
    component: ServiceInspection,
  },
  {
    path: '/',
    name: 'home',
    meta: {
      beforeResolve(to, from, next) {
        const name = store.getters['auth/isLoggedIn'] ? 'schedule' : 'login';
        next({
          name,
        });
      },
    },
  },
  /** 로그인 */
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
      beforeResolve(to, from, next) {
        if (store.getters['auth/isLoggedIn'] && !to.query.redirect) {
          next({
            name: 'schedule',
          });
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/unified-account',
    name: 'unified-account',
    component: UnifiedAccount,
    meta: {
      auth: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      auth: true,
      beforeResolve(to, from, next) {
        store.dispatch('auth/logout');
        next({
          name: 'login',
        });
      },
    },
  },
  {
    path: '/find_id',
    name: 'find_id',
    component: FindId,
    meta: {
      auth: false,
    },
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: ResetPassword,
    meta: {
      auth: false,
    },
  },
  {
    path: '/staff/:token/password',
    name: 'reset_password_link',
    component: ResetPasswordLink,
    meta: {
      auth: false,
    },
  },
  {
    path: '/staff/:token/account',
    name: 'create_account',
    component: CreateStaffAccount,
    meta: {
      auth: false,
    },
  },
  /** 체크인 */
  {
    path: '/checkin',
    name: 'checkin',
    component: Checkin,
    meta: {
      auth: true,
    },
  },
  {
    path: '/checkin/list',
    name: 'enter-list',
    component: Checkin,
    meta: {
      auth: true,
    },
  },
  /** 일정 */
  {
    path: '/schedule',
    name: 'schedule',
    component: Schedule,
    meta: {
      auth: true,
    },
  },
  /** 회원 */
  {
    path: '/users',
    name: 'users',
    component: Members,
    meta: {
      auth: true,
    },
  },
  {
    path: '/users/counseling',
    name: 'counsels',
    component: Counsels,
    meta: {
      auth: true,
    },
  },
  {
    path: '/users/ticket-history',
    name: 'ticket-history',
    component: TicketHistory,
    meta: {
      auth: true,
    },
  },
  {
    path: '/users/create',
    name: 'create_user',
    component: MemberForm,
    meta: {
      auth: true,
    },
  },
  {
    path: '/users/edit',
    name: 'update_user',
    component: MemberForm,
    meta: {
      auth: true,
    },
  },
  {
    path: '/users/detail',
    name: 'user_detail',
    component: MemberDetail,
    meta: {
      auth: true,
    },
  },
  {
    path: '/users/:id/bulk_bookings',
    name: 'bulk_bookings',
    component: BulkBookings,
    meta: {
      auth: true,
    },
  },
  /** 강사 */
  {
    path: '/staffs',
    name: 'staffs',
    component: Staffs,
    meta: {
      auth: true,
    },
  },
  {
    path: '/staffs/detail',
    name: 'staff_detail',
    component: StaffDetail,
    meta: {
      auth: true,
    },
  },
  {
    path: '/staffs/create',
    name: 'create_staff',
    component: StaffForm,
    meta: {
      auth: true,
    },
  },
  {
    path: '/staffs/edit',
    name: 'update_staff',
    component: StaffForm,
    meta: {
      auth: true,
    },
  },
  {
    path: '/staffs/me',
    name: 'my_page',
    component: StaffDetail,
    meta: {
      auth: true,
    },
  },
  /** 수강권 */
  {
    path: '/products',
    name: 'products',
    component: Products,
    meta: {
      auth: true,
    },
  },
  {
    path: '/products/detail',
    name: 'product_detail',
    component: ProductDetail,
    meta: {
      auth: true,
    },
  },
  {
    path: '/products/product',
    component: ProductsFormContainer,
    children: [
      {
        path: 'create',
        name: 'create_product',
        meta: {
          auth: true,
        },
      },
      {
        path: 'edit',
        name: 'update_product',
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/products/ticket',
    component: ProductsFormContainer,
    children: [
      {
        path: 'create',
        name: 'create_ticket',
        meta: {
          auth: true,
        },
      },
      {
        path: 'edit',
        name: 'update_ticket',
        meta: {
          auth: true,
        },
      },
    ],
  },
  /** 수업 */
  {
    path: '/lectures',
    name: 'lectures',
    component: Lectures,
    meta: {
      auth: true,
    },
  },
  {
    path: '/lectures/bookings',
    name: 'bookings',
    component: Bookings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/lectures/deleted',
    name: 'lectures_deleted',
    component: DeletedLectures,
    meta: {
      auth: true,
    },
  },
  {
    path: '/lectures/check_in',
    name: 'lectures_check_in',
    component: CheckinLectures,
    meta: {
      auth: true,
    },
  },
  /** 메시지 */
  {
    path: '/message',
    name: 'message-form',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/message/list',
    name: 'message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/message/:id/edit',
    name: 'message-form',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/message/:id',
    name: 'message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },

  {
    path: '/message/auto-message/list',
    name: 'auto-message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/message/auto-message/:id',
    name: 'auto-message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sms',
    name: 'sms-message-form',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sms/list',
    name: 'sms-message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sms/:id',
    name: 'sms-message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sms/:id/edit',
    name: 'sms-message-form',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sms/auto-message/list',
    name: 'sms-auto-message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sms/auto-message/:id',
    name: 'sms-auto-message-list',
    component: Messages,
    meta: {
      auth: true,
    },
  },
  /* 락커 */
  {
    path: '/locker',
    name: 'locker-total',
    component: Locker,
    meta: {
      auth: true,
    },
  },
  {
    path: '/locker/:id',
    component: Locker,
    meta: {
      auth: true,
    },
  },
  /** 설정 */
  {
    path: '/settings',
    component: Settings,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/',
        component: SettingsMain,
        name: 'SettingsMain',
        meta: {
          auth: true,
        },
      },
      {
        path: 'required',
        component: SettingsRequired,
        name: 'SettingsRequired',
        meta: {
          auth: true,
        },
      },
      {
        path: 'operations',
        component: SettingsOperations,
        name: 'SettingsOperations',
        meta: {
          auth: true,
        },
      },
      {
        path: 'permissions',
        component: SettingsPermissions,
        name: 'SettingsPermissions',
        meta: {
          auth: true,
        },
      },
      {
        path: 'rooms',
        component: SettingsRooms,
        name: 'SettingsRooms',
        meta: {
          auth: true,
        },
      },
      {
        path: 'divisions',
        component: SettingsDivisions,
        name: 'SettingsDivisions',
        meta: {
          auth: true,
        },
      },
      {
        path: 'user-grades',
        component: SettingsUserGrades,
        name: 'SettingsUserGrades',
        meta: {
          auth: true,
        },
      },
      {
        path: 'payments', // 포인트 충전
        component: SettingsPayments,
        name: 'SettingsPayments',
        meta: {
          auth: true,
        },
      },
      {
        path: 'payments/info', // 포인트 충전 결제 정보
        component: SettingsPayments,
        name: 'SettingsPayments_paymentInfo',
        meta: {
          auth: true,
        },
      },
      {
        path: 'service-payments', //서비스 결제
        component: SettingsServicePayments,
        name: 'SettingsServicePayments',
        meta: {
          auth: true,
        },
      },
      {
        path: 'service-payments/info', //서비스 결제 정보
        component: SettingsServicePayments,
        name: 'SettingsServicePayments_paymentInfo',
        meta: {
          auth: true,
        },
      },
      {
        path: 'automessage-notification', //자동 알림 설정
        component: SettingsAutoMessageNotification,
        name: 'SettingsAutoMessageNoti',
        meta: {
          auth: true,
        },
      },
    ],
  },
  /** Boards */
  {
    path: '/boards',
    name: 'notice',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  {
    path: '/boards/notice/:id',
    name: 'notice',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  {
    path: '/boards/notice/form',
    name: 'notice',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  {
    path: '/boards/list-qna',
    name: 'qna',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  {
    path: '/boards/qna/:id',
    name: 'qna',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  {
    path: '/boards/qna/form',
    name: 'qna',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  {
    path: '/boards/studio-notice',
    name: 'studio-notice',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  {
    path: '/boards/studio-notice/:id',
    name: 'studio-notice',
    component: Boards,
    meta: {
      auth: true,
    },
  },
  /** 매출 */
  { path: '/sales-legacy', name: 'SalesLegacy', component: Sales, meta: { auth: true } },
  {
    path: '/sales',
    name: 'Sales',
    component: SalesContainer,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/',
        component: SalesNew,
        name: 'SalesNew',
        meta: {
          auth: true,
        },
      },
      {
        path: 'spending',
        component: SalesSpending,
        name: 'SalesSpending',
        meta: {
          auth: true,
        },
      },
      {
        path: 'unpaid',
        component: SalesUnpaid,
        name: 'SalesUnpaid',
        meta: {
          auth: true,
        },
      },
      {
        path: 'point',
        component: SalesPoint,
        name: 'SalesPoint',
        meta: {
          auth: true,
        },
      },
      {
        path: 'report',
        component: SalesReport,
        name: 'SalesReport',
        meta: {
          auth: true,
        },
      },
    ],
  },
  /** 클래스/수업 생성/수정 */
  {
    path: '/course/private/create',
    name: 'create_private_lesson',
    component: CreatePrivateLesson,
    meta: {
      auth: true,
    },
  },
  {
    path: '/course/group/create',
    name: 'create_group_lesson',
    component: GroupLessonForm,
    meta: {
      auth: true,
    },
  },
  {
    path: '/course/group/edit',
    name: 'update_group_lesson',
    component: GroupLessonForm,
    meta: {
      auth: true,
    },
  },
  {
    path: '/lecture/detail',
    name: 'lecture_detail',
    component: LectureDetail,
    meta: {
      auth: true,
    },
  },
  {
    path: '/lecture/edit',
    name: 'update_lecture',
    component: LectureEdit,
    meta: {
      auth: true,
    },
  },
  /** Not Found */
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
];
