import _ from 'lodash';

export default {
  computed: {
    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
    isStudioOwner() {
      return this.$store.getters['auth/isStudioOwner'];
    },
    userPermissions() {
      return this.$store.getters['auth/userPermissions'];
    },
    studioPolicies() {
      return this.$store.getters['studio/policies'];
    },

    /** 시설 */
    canUpdateFacilitySettings() {
      // 시설 정보 설정
      return this.isStudioOwner || this.includes(101310);
    },
    canUpdateOperationsSettings() {
      // 운영 정보 설정
      return this.isStudioOwner || this.includes(101311);
    },
    canUpdateFacilityRolesAndPermissions() {
      // 역할별 권한 설정
      return this.isStudioOwner || this.includes(101312);
    },
    canUpdateRoomsSettings() {
      // 룸 관리 설정
      return this.isStudioOwner || this.includes(101317);
    },
    canViewStaff() {
      // 스태프 조회
      return this.isStudioOwner || this.includes(101313);
    },
    canCreateStaff() {
      // 스태프 등록
      return this.isStudioOwner || (this.canViewStaff && this.includes(101314));
    },
    canUpdateStaff() {
      // 스태프 정보 수정
      return this.isStudioOwner || (this.canViewStaff && this.includes(101315));
    },
    canDeleteStaff() {
      // 스태프 삭제
      return this.isStudioOwner || (this.canViewStaff && this.includes(101316));
    },

    /** 회원 */
    canViewMembers() {
      // 회원 조회
      return this.isStudioOwner || this.includes(101410);
    },
    canExcelDownloadMembers() {
      // 회원 목록 엑셀 다운로드
      return this.isStudioOwner || (this.canViewMembers && this.includes(101421));
    },
    canCreateMembers() {
      // 회원 등록
      return this.isStudioOwner || (this.canViewMembers && this.includes(101411));
    },
    canUpdateMembers() {
      // 회원 정보 수정
      return this.isStudioOwner || (this.canViewMembers && this.includes(101412));
    },
    canViewMembersMobile() {
      // 회원의 휴대폰 보기
      return this.isStudioOwner || (this.canViewMembers && this.includes(101413));
    },
    canDeleteMembers() {
      // 회원 삭제
      return this.isStudioOwner || (this.canViewMembers && this.includes(101414));
    },
    canCreateMembersTicket() {
      // 회원에게 수강권 발급
      return this.isStudioOwner || (this.canViewMembers && this.includes(101415));
    },
    canUpdateMembersTicket() {
      // 회원의 수강권 정보 수정
      return this.isStudioOwner || (this.canViewMembers && this.includes(101416));
    },

    /** 상담 고객 */
    canViewCounsel() {
      // 상담 고객 조회
      return this.isStudioOwner || this.includes(101420);
    },
    canCreateCounsel() {
      // 상담 고객 등록
      return this.isStudioOwner || (this.canViewCounsel && this.includes(101417));
    },
    canUpdateCounsel() {
      // 상담 고객 수정
      return this.isStudioOwner || (this.canViewCounsel && this.includes(101418));
    },
    canDeleteCounsel() {
      // 상담 고객 삭제
      return this.isStudioOwner || (this.canViewCounsel && this.includes(101419));
    },

    /** 수강권 */
    canCreateProduct() {
      // 수강권 등록
      return this.isStudioOwner || this.includes(101510);
    },
    canUpdateProduct() {
      // 수강권 수정
      return this.isStudioOwner || this.includes(101511);
    },
    canDeleteProduct() {
      // 수강권 판매 정지 및 판매 재개
      return this.isStudioOwner || this.includes(101512);
    },
    canViewSales() {
      // 매출 열람
      return this.isStudioOwner || this.includes(101513);
    },

    /** 수업 보기 권한 */
    canViewMyGroupLecture() {
      // 본인의 그룹 수업 보기
      return true;
    },
    canViewMyPrivateLecture() {
      // 본인의 프라이빗 수업 보기
      return true;
    },
    canViewOthersGroupLecture() {
      // 다른 스태프의 그룹 수업 보기
      return this.isStudioOwner || this.includes(101216);
    },
    canViewOthersPrivateLecture() {
      // 다른 스태프의 프라이빗 수업 보기
      return this.isStudioOwner || this.includes(101221);
    },
    /** 수업 생성 권한 */
    canCreateMyGroupLecture() {
      // 본인의 그룹 수업 생성
      return this.isStudioOwner || this.includes(101207);
    },
    canCreateMyPrivateLecture() {
      // 본인의 프라이빗 수업 생성
      return this.isStudioOwner || this.includes(101211);
    },
    canCreateOthersGroupLecture() {
      // 다른 스태프의 그룹 수업 생성
      return this.isStudioOwner || (this.canViewOthersGroupLecture && this.includes(101215));
    },
    canCreateOthersPrivateLecture() {
      // 다른 스태프의 프라이빗 수업 생성
      return this.isStudioOwner || (this.canViewOthersPrivateLecture && this.includes(101220));
    },

    /** 수업 수정 권한 */
    canUpdateMyGroupLecture() {
      // 본인의 그룹 수업 수정
      return this.isStudioOwner || this.includes(101208);
    },
    canUpdateMyPrivateLecture() {
      // 본인의 프라이빗 수업 수정
      return this.isStudioOwner || this.includes(101212);
    },
    canUpdateOthersGroupLecture() {
      // 다른 스태프의 그룹 수업 수정
      return this.isStudioOwner || (this.canViewOthersGroupLecture && this.includes(101217));
    },
    canUpdateOthersPrivateLecture() {
      // 다른 스태프의 프라이빗 수업 수정
      return this.isStudioOwner || (this.canViewOthersPrivateLecture && this.includes(101222));
    },

    /** 수업 삭제 권한 */
    canDeleteMyGroupLecture() {
      // 본인의 그룹 수업 삭제
      return this.isStudioOwner || this.includes(101210);
    },
    canDeleteMyPrivateLecture() {
      // 본인의 프라이빗 수업 삭제
      return this.isStudioOwner || this.includes(101214);
    },
    canDeleteOthersGroupLecture() {
      // 다른 스태프의 그룹 수업 삭제
      return this.isStudioOwner || (this.canViewOthersGroupLecture && this.includes(101219));
    },
    canDeleteOthersPrivateLecture() {
      // 다른 스태프의 프라이빗 수업 삭제
      return this.isStudioOwner || (this.canViewOthersPrivateLecture && this.includes(101224));
    },

    /** 수업 회원 변경 권한 */
    canUpdateMyGroupLectureBookings() {
      // 본인의 그룹 수업 예약 변경
      return this.isStudioOwner || this.includes(101209);
    },
    canUpdateMyPrivateLectureBookings() {
      // 본인의 프라이빗 수업 예약 변경
      return this.isStudioOwner || this.includes(101213);
    },
    canUpdateOthersGroupLectureBookings() {
      // 다른 스태프의 그룹 수업 예약 변경
      return this.isStudioOwner || (this.canViewOthersGroupLecture && this.includes(101218));
    },
    canUpdateOthersPrivateLectureBookings() {
      // 다른 스태프의 프라이빗 수업 예약 변경
      return this.isStudioOwner || (this.canViewOthersPrivateLecture && this.includes(101223));
    },

    /** 수업 회원 예약 취소 권한 */
    canDeleteMyGroupLectureBookings() {
      // 본인의 그룹 수업 예약 취소
      return this.isStudioOwner || this.includes(101225);
    },
    canDeleteMyPrivateLectureBookings() {
      // 본인의 프라이빗 수업 예약 취소
      return this.isStudioOwner || this.includes(101226);
    },
    canDeleteOthersGroupLectureBookings() {
      // 다른 스태프의 그룹 수업 예약 취소
      return this.isStudioOwner || (this.canViewOthersGroupLecture && this.includes(101227));
    },
    canDeleteOthersPrivateLectureBookings() {
      // 다른 스태프의 프라이빗 수업 예약 취소
      return this.isStudioOwner || (this.canViewOthersPrivateLecture && this.includes(101228));
    },

    /** 공지사항 게시판 관련 권한 */
    canViewNoticeBoard() {
      // 공지사항 게시판 보기
      return this.isStudioOwner || this.includes(101610);
    },
    canCreateNoticeBoard() {
      // 공지사항 게시판 등록
      return this.isStudioOwner || this.includes(101611);
    },
    canDeleteNoticeBoard() {
      // 공지사항 게시판 삭제
      return this.isStudioOwner || this.includes(101612);
    },

    /** 문의 게시판 관련 권한 */
    canViewQnaBoard() {
      // 문의 게시판 보기
      return this.isStudioOwner || this.includes(101620);
    },
    canCreateQnaBoard() {
      // 문의 댓글 등록 / 수정 / 삭제
      return this.isStudioOwner || this.includes(101621);
    },
    canDeleteQnaBoard() {
      // 문의 댓글 삭제
      return this.isStudioOwner || this.includes(101622);
    },

    /** 문자 메세지 관련 권한 */
    canViewSmsMessage() {
      // 문자 메세지 발송
      return this.isStudioOwner || this.includes(101637);
    },

    /** 문자 메세지 관련 권한 */
    canSendSmsMessage() {
      // 문자 메세지 발송
      return this.isStudioOwner || this.includes(101638);
    },

    canUpdateSmsMessage() {
      // 문자 메세지 수정 / 예약 메세지 취소
      return this.isStudioOwner || this.includes(101639);
    },

    canDeleteSmsMessage() {
      // 문자 메세지 삭제
      return this.isStudioOwner || this.includes(101640);
    },

    canViewAppPushMessage() {
      // 앱 푸시 메세지 발송
      return this.isStudioOwner || this.includes(101641);
    },

    canSendAppPushMessage() {
      // 앱 푸시 메세지 발송
      return this.isStudioOwner || this.includes(101642);
    },

    canUpdateAppPushMessage() {
      // 앱 푸시 메세지 수정 / 예약 메세지 취소
      return this.isStudioOwner || this.includes(101643);
    },

    canDeleteAppPushMessage() {
      // 앱 푸시 메세지 삭제
      return this.isStudioOwner || this.includes(101644);
    },
  },

  methods: {
    includes(id) {
      return _.includes(this.userPermissions, id);
    },

    canCreateGroupLecture(instructorId) {
      if (!instructorId) return this.canCreateMyGroupLecture || this.canCreateOthersGroupLecture;
      if (instructorId === this.currentUser.id) return this.canCreateMyGroupLecture;
      else return this.canCreateOthersGroupLecture;
    },

    canCreatePrivateLecture(instructorId) {
      if (!instructorId) return this.canCreateMyPrivateLecture || this.canCreateOthersPrivateLecture;
      if (instructorId === this.currentUser.id) return this.canCreateMyPrivateLecture;
      else return this.canCreateOthersPrivateLecture;
    },

    canViewLecture(courseType, lectureInstructorId) {
      const isGroup = courseType === 'G';
      const isPrivate = courseType === 'P';
      const isMyLecture = lectureInstructorId === this.currentUser.id;

      if (isGroup && isMyLecture) return this.canViewMyGroupLecture;
      else if (isGroup && !isMyLecture) return this.canViewOthersGroupLecture;
      else if (isPrivate && isMyLecture) return this.canViewMyPrivateLecture;
      else if (isPrivate && !isMyLecture) return this.canViewOthersPrivateLecture;

      return false;
    },

    canUpdateLecture(courseType, lectureInstructorId) {
      const isGroup = courseType === 'G';
      const isPrivate = courseType === 'P';
      const isMyLecture = lectureInstructorId === this.currentUser.id;
      const canViewLecture = this.canViewLecture(courseType, lectureInstructorId);

      if (isGroup && isMyLecture) return canViewLecture && this.canUpdateMyGroupLecture;
      else if (isGroup && !isMyLecture) return canViewLecture && this.canUpdateOthersGroupLecture;
      else if (isPrivate && isMyLecture) return canViewLecture && this.canUpdateMyPrivateLecture;
      else if (isPrivate && !isMyLecture) return canViewLecture && this.canUpdateOthersPrivateLecture;

      return false;
    },

    canDeleteLecture(courseType, lectureInstructorId) {
      const isGroup = courseType === 'G';
      const isPrivate = courseType === 'P';
      const isMyLecture = lectureInstructorId === this.currentUser.id;
      const canViewLecture = this.canViewLecture(courseType, lectureInstructorId);

      if (isGroup && isMyLecture) return canViewLecture && this.canDeleteMyGroupLecture;
      else if (isGroup && !isMyLecture) return canViewLecture && this.canDeleteOthersGroupLecture;
      else if (isPrivate && isMyLecture) return canViewLecture && this.canDeleteMyPrivateLecture;
      else if (isPrivate && !isMyLecture) return canViewLecture && this.canDeleteOthersPrivateLecture;

      return false;
    },

    canUpdateLectureBookings(courseType, lectureInstructorId) {
      const isGroup = courseType === 'G';
      const isPrivate = courseType === 'P';
      const isMyLecture = lectureInstructorId === this.currentUser.id;
      const canViewLecture = this.canViewLecture(courseType, lectureInstructorId);

      if (isGroup && isMyLecture) return canViewLecture && this.canUpdateMyGroupLectureBookings;
      else if (isGroup && !isMyLecture) return canViewLecture && this.canUpdateOthersGroupLectureBookings;
      else if (isPrivate && isMyLecture) return canViewLecture && this.canUpdateMyPrivateLectureBookings;
      else if (isPrivate && !isMyLecture) return canViewLecture && this.canUpdateOthersPrivateLectureBookings;

      return false;
    },

    canDeleteLectureBookings(courseType, lectureInstructorId) {
      const isGroup = courseType === 'G';
      const isPrivate = courseType === 'P';
      const isMyLecture = lectureInstructorId === this.currentUser.id;
      const canViewLecture = this.canViewLecture(courseType, lectureInstructorId);

      if (isGroup && isMyLecture) return canViewLecture && this.canDeleteMyGroupLectureBookings;
      else if (isGroup && !isMyLecture) return canViewLecture && this.canDeleteOthersGroupLectureBookings;
      else if (isPrivate && isMyLecture) return canViewLecture && this.canDeleteMyPrivateLectureBookings;
      else if (isPrivate && !isMyLecture) return canViewLecture && this.canDeleteOthersPrivateLectureBookings;

      return false;
    },
  },
};
